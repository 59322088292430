import React, { useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { useProtocolInstanceContext } from 'client/app/apps/protocols/context/ProtocolInstanceProvider';
import { useSimulationContext } from 'client/app/apps/protocols/context/SimulationProvider';
import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import { useWorkflowContext } from 'client/app/apps/protocols/context/WorkflowProvider';
import { EditProtocolInstanceHeader } from 'client/app/apps/protocols/EditProtocolInstanceHeader';
import { InputStepList } from 'client/app/apps/protocols/InputStepList';
import {
  InputStepName,
  InputStepParameters,
  ParameterEditorList,
  ParameterSkeletonList,
} from 'client/app/apps/protocols/InputStepParameters';
import { useComplexParameterEditorDialogManager } from 'client/app/apps/protocols/lib/utils';
import {
  OutputStepPreviewPanel,
  OutputStepPreviewTitle,
} from 'client/app/apps/protocols/OutputStepPreview';
import { OutputStepResponse } from 'client/app/apps/protocols/OutputStepResponse/OutputStepResponse';
import { OutputStepResponseValidationIndicator } from 'client/app/apps/protocols/OutputStepResponse/OutputStepResponseValidationIndicator';
import { useStepsResponse } from 'client/app/apps/protocols/OutputStepResponse/useStepsReponse';
import ProtocolInfo from 'client/app/apps/protocols/ProtocolInfo';
import { ProtocolInstanceReadOnlyBar } from 'client/app/apps/protocols/ProtocolInstanceReadOnlyBar';
import { useCurrentEntity } from 'client/app/components/nav/breadcrumbs/BreadcrumbsEntityContext';
import Colors from 'common/ui/Colors';

export const EditProtocolInstance = () => {
  const {
    loading: workflowLoading,
    workflowConfig,
    elementsConfig,
    elementsContext,
    getSchemaParameters,
    updateOutput,
  } = useWorkflowContext();
  const {
    loading: protocolInstanceLoading,
    protocolInstance,
    updateProtocolInput,
    updateConflictDialog,
  } = useProtocolInstanceContext();
  const { simulation, isSimulating, clearSimulation } = useSimulationContext();
  const { protocolSteps, stepsConfig, selectedStep, handleSelectStep } =
    useStepsContext();
  const stepsResponse = useStepsResponse(
    protocolSteps,
    stepsConfig,
    elementsConfig,
    elementsContext,
    simulation?.errors,
  );
  const [hideErrors, setHideErrors] = useState(false);
  const isReadOnly = false;
  const activeOutput = selectedStep?.outputs[0];
  const dialogs = useComplexParameterEditorDialogManager();

  const { showInfo } = useCurrentEntity();
  // Because the `showInfo` state is lifted, it isn't reset when the component changes.
  // So we store and compare against the initial value to ensure we default to not showing
  const [initialShowInfo] = useState(showInfo);

  const stepName = selectedStep
    ? selectedStep.displayName
    : workflowLoading
    ? 'Loading step...'
    : 'Unnamed step';

  const parameterList =
    workflowLoading || selectedStep === undefined ? (
      <ParameterSkeletonList />
    ) : (
      <ParameterEditorList
        inputParameters={getSchemaParameters(selectedStep.inputs)}
        workflowConfig={workflowConfig}
        updateParameter={updateProtocolInput}
      />
    );

  const outputTitle = activeOutput
    ? `Preview ${activeOutput.displayName}`
    : workflowLoading
    ? 'Loading preview...'
    : 'Preview';

  const isOutputLoading = workflowLoading || protocolInstanceLoading;

  // awkwardly element output preview depends on workflow builder context
  useEffect(() => {
    activeOutput && updateOutput(activeOutput.id);
  }, [activeOutput, updateOutput]);

  return (
    <>
      {isReadOnly ? (
        <ProtocolInstanceReadOnlyBar protocolInstanceId={protocolInstance.id} />
      ) : null}
      <EditProtocolInstanceHeader />
      <Wrapper>
        <InputStepList />
        <InputsAndOutputsWrapper>
          <InputStepParameters
            header={<InputStepName variant="h4">{stepName}</InputStepName>}
          >
            {parameterList}
          </InputStepParameters>
          <OutputStepPreviewPanel noOutput={activeOutput === undefined}>
            <Stack direction="row" justifyContent="space-between">
              <OutputStepPreviewTitle variant="h4">{outputTitle}</OutputStepPreviewTitle>
              <OutputStepResponseValidationIndicator
                // we should show a loading skeleton for output loading; so not busy
                loadingStatus={isOutputLoading ? 'neutral' : isSimulating ? 'busy' : 'ok'}
                stepsResponse={stepsResponse}
                buttonPrefix={hideErrors ? 'View' : 'Hide'}
                onClick={() => setHideErrors(prev => !prev)}
              />
            </Stack>
            <OutputStepResponse
              loading={isOutputLoading}
              selectedStepId={selectedStep?.id}
              ignoreErrors={hideErrors}
              stepsResponse={stepsResponse}
              handleSelectStep={handleSelectStep}
              clearSimulation={clearSimulation}
            />
          </OutputStepPreviewPanel>
        </InputsAndOutputsWrapper>
        {dialogs}
        {updateConflictDialog}
        {showInfo !== initialShowInfo && (
          <ProtocolInfo protocol={protocolInstance.protocol} />
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplate: `
      "list inputsAndOutputs inputsAndOutputs" minmax(400px, 1200px)
      / auto 1fr 1fr`,
  padding: theme.spacing(8),
  gap: theme.spacing(7),
  height: '100%',
  overflow: 'auto',
  backgroundColor: Colors.GREY_10,
  position: 'relative',
}));

const InputsAndOutputsWrapper = styled('div')({
  gridArea: 'inputsAndOutputs',
  display: 'flex',
  overflowX: 'auto',
  zIndex: 1,
});
