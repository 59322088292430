import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useFetchGraphQLElementSet } from 'client/app/api/ElementsApi';
import { GraphQLWorkflow } from 'client/app/api/gql/utils';
import { useUpdateWorkflow } from 'client/app/apps/protocols/api/ProtocolsAPI';
import { useUpdateEntity } from 'client/app/apps/protocols/lib/utils';
import { deserialiseWorkflowResponse } from 'client/app/apps/workflow-builder/lib/workflowUtils';
import { getOutputVisualisationTypeFromParameterType } from 'client/app/components/ElementPlumber/ElementOutputs/helpers';
import { getParameterEditorProps } from 'client/app/components/Parameters/ElementParameterGroupList';
import { getPlateContentParams } from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import { ElementSetQuery } from 'client/app/gql';
import ParameterStateContextProvider from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import AutocompleteParameterValuesContextProvider from 'client/app/state/AutocompleteParameterValuesContext';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { AdditionalEditorProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { isDefined } from 'common/lib/data';
import {
  ElementContextMap,
  ElementInstance,
  emptyWorkflowConfig,
  Parameter,
  WorkflowConfig,
} from 'common/types/bundle';
import { ErrorCodes } from 'common/types/errorCodes';
import { ProtocolStepInput } from 'common/types/Protocol';
import { getElementId, getElementParameterName, Schema } from 'common/types/schema';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

const emptySchema = () => {
  return { inputs: [], outputs: [] };
};

/** SchemaParameter is a SchemaInput transformed with workflow context */
export type SchemaParameter = {
  id: string;
  element: ElementInstance;
  parameter: Parameter;
  parameterValue: any;
  editorProps?: AdditionalEditorProps;
};

/** ElementParamConfig describes workflow element set renaming of elements / parameters */
export type ElementParamConfig = {
  element: { typeName: string; displayName: string };
  parameters: { [paramName: string]: string };
};

type WorkflowContextType = {
  id: WorkflowId;
  workflowConfig: WorkflowConfig;
  elementsConfig: { [elementId: string]: ElementParamConfig };
  elementsContext: ElementContextMap;
  loading: boolean;
  conflictDialog: JSX.Element | null;
  /** update workflow state parameter input */
  updateInput: (schemaInputId: string, value: any) => void;
  /** update workflow state parameter output to display */
  updateOutput: (schemaInputId: string) => void;
  /** update workflow state with context of element outputs from being ran */
  updateElementContext: (context: ElementContext) => void;
  /** triggers updateWorkflow mutation with provided params */
  handleUpdateSchema: (update: Schema) => void;
  getSchemaParameters: (inputs: ProtocolStepInput[]) => SchemaParameter[];
  getElementParameterValue: (elementInstance: ElementInstance, param: Parameter) => any;
  getElementInstance: (elementInstanceId: string) => ElementInstance | undefined;
};

export const WorkflowContext = createContext<WorkflowContextType>({
  id: '' as WorkflowId,
  workflowConfig: emptyWorkflowConfig(),
  elementsConfig: {},
  elementsContext: {},
  loading: false,
  conflictDialog: null,
  updateInput: () => {},
  updateOutput: () => {},
  updateElementContext: () => {},
  handleUpdateSchema: () => {},
  getSchemaParameters: () => [],
  getElementParameterValue: () => undefined,
  getElementInstance: () => undefined,
});

export const useWorkflowContext = () => {
  const context = useContext(WorkflowContext);

  if (context === undefined) {
    throw new Error('useWorkflowContext must be used within a WorkflowProvider');
  }

  return context;
};

type WorkflowProviderProps = {
  workflow: GraphQLWorkflow;
  elementContext: ElementContext;
} & PropsWithChildren;

type ElementContext = {
  elementContextMap: ElementContextMap | null;
  elementContextError: CoreErrorBlob | null;
};

export const WorkflowProvider: FC<WorkflowProviderProps> = ({
  elementContext: initialElementContext,
  workflow,
  children,
}) => {
  const dispatch = useWorkflowBuilderDispatch();
  const snackbar = useSnackbarManager();
  const fetchGraphQLElementSet = useFetchGraphQLElementSet();
  const [elementSet, setElementSet] = useState<ElementSetQuery['elementSet']>();
  const { handleUpdateWorkflow, loading: updateLoading } = useUpdateWorkflow();
  const [schema, setSchema] = useState(workflow.workflow.Schema || emptySchema());
  const loading = elementSet === undefined || updateLoading;
  const [elementContext, setElementContext] = useState(initialElementContext);

  // elementSet should only be fetched once since since we do not allow
  // uploading workflows or changing branches in protocols. Moreover the query
  // is normally quite expensive
  useEffect(() => {
    (async () => {
      try {
        setElementSet(await fetchGraphQLElementSet(workflow.id));
      } catch (err) {
        snackbar.showError(err);
      }
    })();
  }, [dispatch, fetchGraphQLElementSet, snackbar, workflow.id]);

  // on the other hand, workflow state may change depending on how the provider
  // is used and is cheap to update
  useEffect(() => {
    if (loading) return;
    const { workflowState, errors } = deserialiseWorkflowResponse(workflow, elementSet);
    if (errors.length > 0) {
      snackbar.showError(errors.join(' '));
    }
    dispatch({
      type: 'resetToWorkflow',
      payload: workflowState,
    });
  }, [dispatch, elementSet, loading, snackbar, workflow]);

  useEffect(() => {
    if (loading) return;
    if (elementContext.elementContextMap) {
      dispatch({
        type: 'updateElementsWithContexts',
        payload: elementContext.elementContextMap,
      });
    }
    if (elementContext.elementContextError) {
      dispatch({
        type: 'setElementContextError',
        payload: elementContext.elementContextError,
      });
    }
  }, [
    dispatch,
    elementContext.elementContextError,
    elementContext.elementContextMap,
    loading,
  ]);

  const { conflictDialog, setUpdateRequired } = useUpdateEntity({
    entityType: 'workflow',
    editVersion: workflow.version,
    conflictCode: ErrorCodes.WORKFLOW_EDIT_CONFLICT,
    handleUpdate: useCallback(
      async (editVersion: number) => {
        const params = { ...workflow.workflow, Schema: schema };
        await handleUpdateWorkflow(workflow.id, editVersion, params);
      },
      [handleUpdateWorkflow, schema, workflow.id, workflow.workflow],
    ),
  });

  const {
    config: workflowConfig,
    parameters: allElementParametersValues,
    elementInstances,
    outputPreviewProps,
    InstancesConnections: connections,
  } = useWorkflowBuilderSelector(state => state);

  const schemaInputsById = useMemo(
    () => Object.fromEntries(schema.inputs?.map(input => [input.id, input]) || []),
    [schema.inputs],
  );

  const schemaOutputsById = useMemo(
    () => Object.fromEntries(schema.outputs?.map(output => [output.id, output]) || []),
    [schema.outputs],
  );

  const elementsById = useMemo(
    () => Object.fromEntries(elementInstances.map(instance => [instance.Id, instance])),
    [elementInstances],
  );

  const elementsConfig: { [elementId: string]: ElementParamConfig } = useMemo(() => {
    const elementEntries = elementInstances.map(instance => {
      const { TypeName: typeName, Id: id, element } = instance;
      const { configuration } = element;
      const { elementDisplayName = typeName, parameters = {} } = configuration || {};
      const paramEntries = Object.entries(parameters);
      const paramRenames = Object.fromEntries(
        paramEntries.map(([name, { displayName }]) => [name, displayName]),
      );
      const paramConfig = {
        element: { typeName, displayName: elementDisplayName },
        parameters: paramRenames,
      };
      return [id, paramConfig];
    });
    return Object.fromEntries(elementEntries);
  }, [elementInstances]);

  const plateContentParamsByElementId = useMemo(
    () =>
      Object.fromEntries(
        elementInstances.map(instance => [
          instance.Id,
          getPlateContentParams(instance.element.inputs),
        ]),
      ),
    [elementInstances],
  );

  const getElementParameterValue = useCallback(
    (instance: ElementInstance, parameter: Parameter) =>
      allElementParametersValues[instance.name]?.[parameter.name],
    [allElementParametersValues],
  );

  const getSchemaParameter = useCallback(
    (schemaInputId: string) => {
      const schemaInput = schemaInputsById[schemaInputId] || { path: [] };
      const { path, typeName } = schemaInput;
      const paramName = getElementParameterName(path);
      const elementId = getElementId(path);
      const element = elementId ? elementsById[elementId] : undefined;
      const parameter = element?.element.inputs.find(({ name }) => name === paramName);
      if (!element || !parameter) return undefined;
      return {
        id: schemaInputId,
        element,
        parameter: { ...parameter, type: typeName },
        parameterValue: allElementParametersValues[element.name][parameter.name],
      };
    },
    [allElementParametersValues, elementsById, schemaInputsById],
  );

  const getSchemaParameters = useCallback(
    (inputs: ProtocolStepInput[]): SchemaParameter[] => {
      return inputs
        .map(input => {
          const schemaParameter = getSchemaParameter(input.id);
          if (!schemaParameter) return undefined;

          const { element, parameter, parameterValue } = schemaParameter;
          const plateContentParams = plateContentParamsByElementId[element.Id];
          const handleDeletePlateContentsParams = (plateName: string) =>
            plateContentParams &&
            dispatch({
              type: 'deletePlateContentsEditorParameters',
              payload: {
                instanceName: element.name,
                plateContentParams: plateContentParams,
                selectedPlateName: plateName,
              },
            });

          return {
            id: input.id,
            element,
            parameterValue,
            parameter: {
              ...parameter,
              configuration: {
                editor: input.configuration || parameter.configuration?.editor,
                isVisible: true,
                displayDescription: input.displayDescription,
                displayName: input.displayName,
              },
            } as Parameter,
            editorProps:
              getParameterEditorProps(
                parameter,
                plateContentParams,
                allElementParametersValues[element.name],
                handleDeletePlateContentsParams,
              ) ?? undefined,
          };
        })
        .filter(isDefined);
    },
    [
      allElementParametersValues,
      dispatch,
      getSchemaParameter,
      plateContentParamsByElementId,
    ],
  );

  const getElementInstance = useCallback(
    (id: string) => elementsById[id],
    [elementsById],
  );

  const updateInput = useCallback(
    (schemaInputId: string, value: any) => {
      // don't use getSchemaParameter to reduce callback dependencies to a minimum
      const schemaInput = schemaInputsById[schemaInputId] || { path: [] };
      const { path } = schemaInput;
      const paramName = getElementParameterName(path);
      const elementId = getElementId(path);
      const element = elementId ? elementsById[elementId] : undefined;
      if (element && paramName) {
        dispatch({
          type: 'updateParameter',
          payload: {
            instanceName: element.name,
            parameterName: paramName,
            value: value,
          },
        });
      }
    },
    [dispatch, elementsById, schemaInputsById],
  );

  const updateOutput = useCallback(
    (schemaOutputId: string) => {
      const schemaOutput = schemaOutputsById[schemaOutputId] || { path: [] };
      const { path, typeName } = schemaOutput;
      const paramName = getElementParameterName(path);
      const elementId = getElementId(path);
      if (elementId && paramName) {
        dispatch({
          type: 'openOutputPreview',
          payload: {
            selectedElementId: elementId,
            selectedOutputParameterName: paramName,
            outputType: getOutputVisualisationTypeFromParameterType(typeName),
            entityView: outputPreviewProps.entityView,
          },
        });
      }
    },
    [dispatch, outputPreviewProps.entityView, schemaOutputsById],
  );

  const handleUpdateSchema = useCallback(
    (update: Schema) => {
      setSchema(update);
      setUpdateRequired(true);
    },
    [setUpdateRequired],
  );

  const state = {
    id: workflow.id,
    workflowConfig,
    elementsConfig,
    elementsContext: elementContext.elementContextMap ?? {},
    loading,
    conflictDialog,
    updateInput,
    updateOutput,
    updateElementContext: setElementContext,
    handleUpdateSchema,
    getSchemaParameters,
    getElementParameterValue,
    getElementInstance,
  };

  return (
    <ParameterStateContextProvider
      parameters={allElementParametersValues}
      elementInstances={elementInstances}
      // must set connections as element configuration rules are dependent on
      // them even if the protocols app is not
      connections={connections}
    >
      <AutocompleteParameterValuesContextProvider
        parameters={allElementParametersValues}
        instances={elementInstances}
      >
        <WorkflowContext.Provider value={state}>{children}</WorkflowContext.Provider>
      </AutocompleteParameterValuesContextProvider>
    </ParameterStateContextProvider>
  );
};
