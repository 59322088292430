import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useUpdateProtocolInstance } from 'client/app/apps/protocols/api/ProtocolsAPI';
import { useWorkflowContext } from 'client/app/apps/protocols/context/WorkflowProvider';
import { useUpdateEntity } from 'client/app/apps/protocols/lib/utils';
import { ProtocolInstanceQuery } from 'client/app/gql';
import { ErrorCodes } from 'common/types/errorCodes';

type ProtocolInstanceContextType = {
  loading: boolean;
  protocolInstance: NonNullable<ProtocolInstanceQuery['protocolInstance']['instance']>;
  updateProtocolInput: (schemaInputId: string, value: any) => void;
  updateConflictDialog: JSX.Element | null;
};

export const ProtocolInstanceContext = createContext<
  ProtocolInstanceContextType | undefined
>(undefined);

type ProtocolInstanceProviderProps = {
  instance: NonNullable<ProtocolInstanceQuery['protocolInstance']['instance']>;
} & PropsWithChildren;

export const useProtocolInstanceContext = () => {
  const context = useContext(ProtocolInstanceContext);

  if (context === undefined) {
    throw new Error(
      'useProtocolInstanceContext must be used within a ProtocolInstanceProvider',
    );
  }

  return context;
};

export const ProtocolInstanceProvider: FC<ProtocolInstanceProviderProps> = ({
  instance,
  children,
}) => {
  const { updateInput: updateWorkflowInput, updateElementContext } = useWorkflowContext();
  const [protocolInputs, setProtocolInputs] = useState(instance.parameters);
  const { handleUpdateProtocolInstance, loading } = useUpdateProtocolInstance(
    instance.id,
  );

  // always ensure protocol inputs override default parameter values first so
  // the users get real time updates
  useEffect(() => {
    Object.entries(protocolInputs).forEach(([id, value]) => {
      updateWorkflowInput(id, value);
    });
  }, [protocolInputs, updateWorkflowInput]);

  const { conflictDialog, setUpdateRequired } = useUpdateEntity({
    entityType: 'protocol instance',
    editVersion: instance.editVersion,
    conflictCode: ErrorCodes.PROTOCOL_INSTANCE_EDIT_CONFLICT,
    handleUpdate: useCallback(
      async (editVersion: number) => {
        const params = { params: protocolInputs };
        const resp = await handleUpdateProtocolInstance(editVersion, params);
        updateElementContext(resp);
      },
      [handleUpdateProtocolInstance, protocolInputs, updateElementContext],
    ),
  });

  const updateProtocolInput = useCallback(
    (schemaInputId: string, value: any) => {
      // important to set null so the protocol input key is retained and set with
      // a null value during serialisation, otherwise the value is not deleted
      // from the perspective of validating instance updates
      setProtocolInputs(prev => ({ ...prev, [schemaInputId]: value ? value : null }));
      setUpdateRequired(true);
    },
    [setUpdateRequired],
  );

  const state = {
    loading,
    protocolInstance: instance,
    updateProtocolInput,
    updateConflictDialog: conflictDialog,
  };

  return (
    <ProtocolInstanceContext.Provider value={state}>
      {children}
    </ProtocolInstanceContext.Provider>
  );
};
