import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useProtocolInstanceContext } from 'client/app/apps/protocols/context/ProtocolInstanceProvider';
import { SimulateProtocolInstanceButton } from 'client/app/apps/protocols/SimulateProtocolInstanceButton';
import Colors from 'common/ui/Colors';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import { TemplateWorkflowIcon } from 'common/ui/icons/TemplateWorkflow';

export const EditProtocolInstanceHeader = () => {
  const { protocolInstance, loading: protocolInstanceLoading } =
    useProtocolInstanceContext();

  // TODO: It is not clear yet how are we going to handle non editable behaviours
  const isReadonly = false;

  return (
    <Wrapper>
      <StyledTemplateWorkflowIcon />
      <TypographyWrapper>
        <ProtocolHeader variant="h4">{protocolInstance.name}</ProtocolHeader>
        <Typography variant="body2">Modify the inputs of your protocol</Typography>
      </TypographyWrapper>
      <RightContentWrapper>
        <SimulateProtocolInstanceButton loading={protocolInstanceLoading || isReadonly} />
      </RightContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'start',
  alignItems: 'center',
  gap: theme.spacing(4),
  borderBottom: `1px solid ${Colors.GREY_30}`,
  padding: theme.spacing(4, 6),
  minHeight: '70px',
}));

const TypographyWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const ProtocolHeader = styled(TypographyWithTooltip)(() => ({
  fontWeight: 600,
}));

const StyledTemplateWorkflowIcon = styled(TemplateWorkflowIcon)(() => ({
  color: '#1773C9',
}));

const RightContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  marginLeft: 'auto',
  alignItems: 'center',
}));
