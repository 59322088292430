import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography, { typographyClasses } from '@mui/material/Typography';
import groupBy from 'lodash/groupBy';

import RulesMeta from 'client/app/components/Parameters/Policy/assets/rulesMeta.json';
import formatRuleConsequenceValueRecursive from 'client/app/components/Parameters/Policy/components/formatRuleConsequenceValueRecursive';
import { LiquidPolicyRule } from 'client/app/gql';
import { formatMeasurementObj, getOrderOperatorExpression } from 'common/lib/format';
import { Rule, RuleConsequenceValue } from 'common/types/mix';
import Colors from 'common/ui/Colors';
import { MessagePreview } from 'common/ui/components/MessagePreview';
import Popover from 'common/ui/components/Popover';

type Props = {
  rules: LiquidPolicyRule[];
};

function RulesPreview({ rules }: Props) {
  if (!Array.isArray(rules)) {
    return <Typography variant="body1">Could not display rules</Typography>;
  }

  const sortedRules = [...rules].sort((a, b) => a.ruleOrder - b.ruleOrder);
  return (
    <Background>
      {sortedRules.map(({ id, ruleData, ruleOrder }) => {
        const rule: Rule = ruleData;
        const conditionGroups = Object.entries(groupBy(rule.conditions, 'variable'));
        const consequenceGroups = Object.entries(RulesMeta.consequences);
        const headline = ruleOrder === 0 ? 'Main Rule' : `Additional Rule #${ruleOrder}`;

        return (
          <Stack key={id}>
            <RuleHeadline>
              <Typography variant="subtitle2">{headline}</Typography>
              {rules.length > 1 && (
                <Typography variant="caption">
                  ({ruleOrder + 1} of {rules.length})
                </Typography>
              )}
            </RuleHeadline>
            <Conditions>
              <Typography variant="body1" fontWeight={700} color="textSecondary">
                If:
              </Typography>
              <Stack direction="row" gap={3} flexWrap="wrap">
                {conditionGroups.map(([variable, conditionValues]) => (
                  <ConditionPreview
                    key={variable}
                    variable={variable}
                    values={conditionValues}
                  />
                ))}
              </Stack>
            </Conditions>
            <Consequences>
              <Typography variant="body1" fontWeight={700} color="textSecondary">
                Then:
              </Typography>
              <Stack gap={6}>
                {consequenceGroups
                  .filter(([_, consequences]) =>
                    Object.keys(consequences).some(
                      consequenceKey => consequenceKey in rule.consequences,
                    ),
                  )
                  .map(([group, consequences]) => (
                    <Stack key={group} gap={5}>
                      <Typography variant="body2" color="textSecondary">
                        {group}
                      </Typography>
                      <Stack direction="row" gap={3} flexWrap="wrap">
                        {Object.entries(consequences).map(([consequenceKey, meta]) => (
                          <ConsequencePreview
                            key={consequenceKey}
                            value={rule.consequences[consequenceKey]}
                            meta={meta}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  ))}
              </Stack>
            </Consequences>
          </Stack>
        );
      })}
    </Background>
  );
}

export default React.memo(RulesPreview);

//#region Helper components

const ConditionPreview = ({
  variable,
  values,
}: {
  variable: string;
  values: Rule['conditions'];
}) => {
  const metaKey = variable as keyof typeof RulesMeta.conditions;
  const meta = RulesMeta.conditions[metaKey];

  const value = values
    ?.map(conditionValue => {
      let result = '';
      if (conditionValue.operator) {
        result += getOrderOperatorExpression(conditionValue.operator) + ' ';
      }
      if (conditionValue.value) {
        result += formatMeasurementObj(conditionValue.value);
      } else if (conditionValue.values) {
        result += conditionValue.values.join(' ; ');
      } else {
        result += 'N/A';
      }
      return result;
    })
    .join(' & ');

  return (
    <Popover
      sx={{
        '&[data-popper-reference-hidden]': {
          visibility: 'hidden',
          pointerEvents: 'none',
        },
      }}
      title={
        <Typography
          variant="caption"
          color="textPrimary"
          component="p"
          sx={{ px: 4, py: 5, lineHeight: '1.6em' }}
        >
          <MessagePreview message={meta.description} messageType="markdown" />
        </Typography>
      }
    >
      <ChipBase variant="secondary">
        <ChipLabel variant="secondary">{meta.label}</ChipLabel>
        <ChipValue variant="secondary">{value}</ChipValue>
      </ChipBase>
    </Popover>
  );
};

const ConsequencePreview = ({
  value,
  meta,
}: {
  value: RuleConsequenceValue | undefined;
  meta: { label: string; description: string };
}) =>
  value === undefined ? null : (
    <Popover
      sx={{
        '&[data-popper-reference-hidden]': {
          visibility: 'hidden',
          pointerEvents: 'none',
        },
      }}
      title={
        <Typography
          variant="caption"
          color="textPrimary"
          component="p"
          sx={{ px: 2, py: 3, lineHeight: '1.6em' }}
        >
          <MessagePreview message={meta.description} messageType="markdown" />
        </Typography>
      }
    >
      <ChipBase variant="primary">
        <ChipLabel variant="primary">{meta.label}</ChipLabel>
        <ChipValue variant="primary">
          {formatRuleConsequenceValueRecursive(value)}
        </ChipValue>
      </ChipBase>
    </Popover>
  );

//#endregion

//#region Styles

const Background = styled('main')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '--chip-primary-bg': Colors.BLUE_0,
  '--chip-primary-border': Colors.BLUE_10,
  '--chip-secondary-bg': Colors.INDIGO_0,
  '--chip-secondary-border': Colors.INDIGO_10,
}));

const RuleHeadline = styled('header')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: Colors.GREY_10,
  padding: theme.spacing(3, 4),
}));

const Conditions = styled('section')(({ theme }) => ({
  position: 'relative',
  margin: theme.spacing(6, 4, 0, 10),

  ['&:before']: {
    position: 'absolute',
    top: 24,
    left: -18,
    height: 'calc(100% + 8px)',
    width: 14,
    content: '""',
    borderLeft: `1px solid ${theme.palette.text.secondary}`,
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    borderBottomLeftRadius: 8,
  },
  [`& .${typographyClasses.body1}`]: {
    position: 'absolute',
    top: 6,
    left: -22,
  },
}));

const Consequences = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),

  margin: theme.spacing(6, 4, 10, 10),
}));

type ChipVariant = 'primary' | 'secondary';

const ChipBase = styled('div')<{ variant: ChipVariant }>(({ variant = 'primary' }) => ({
  display: 'flex',
  alignItems: 'center',

  position: 'relative',
  width: 'fit-content',

  backgroundColor: `var(--chip-${variant}-bg)`,
  border: `1px solid var(--chip-${variant}-border)`,
  borderRadius: 20,

  userSelect: 'none',
}));

const ChipLabel = styled('div')<{ variant: ChipVariant }>(
  ({ theme, variant = 'primary' }) => ({
    fontSize: 12,
    fontWeight: 400,
    textTransform: 'capitalize',

    color: theme.palette[variant].main,
    padding: theme.spacing(3, 3, 3, 5),
  }),
);

const ChipValue = styled('div')<{ variant: ChipVariant }>(
  ({ theme, variant = 'primary' }) => ({
    fontSize: 12,
    fontWeight: 400,

    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,

    padding: theme.spacing(3, 5, 3, 3),
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,

    borderLeft: `1px solid ${theme.palette[variant].light}`,
  }),
);

//#endregion
